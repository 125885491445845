import React, { useState } from 'react';
import { ConsultationsService } from '../services/consultationsService';

function Consultation(props) {
    const [bodyContent, setBodyContent] = useState();
    const [consultation, setConsultation] = useState({});
    React.useEffect( () => {
        let search = window.location;
        const urlParams = search.pathname;
        const consultation_id = urlParams.substring(urlParams.lastIndexOf("/") + 1);
        const consultationsService = new ConsultationsService();
        async function fetchData(){
            await consultationsService.fetchConsultation(consultation_id).then((consultation_returned) => {
                setConsultation(consultation_returned)
                const consultationContent = consultation_returned.content.replaceAll("\\","") //removes the escape characters which is added by s3 which fetching data
                setBodyContent(consultationContent);
            })
        }
        fetchData();
    }, [])

    React.useEffect(()=>{
      if(consultation.hasOwnProperty('title')){
        document.title = consultation.title;
      }
    },[consultation])
    return <div key={bodyContent} dangerouslySetInnerHTML={{__html: bodyContent}}></div>
}

export default Consultation;