import React from 'react';

function Footer(props) {
    return (
        <footer role="contentinfo" className="footer">
            <div className="footer-top container">
                <div className="row">
                    <div className="col-sm-6 col-md-4">
                        <div id="block-block-16" className="logo-header">  <a className="name"
                        href="https://www.mfe.govt.nz/"
                        title="Home">Ministry for the Environment</a>
                        </div>
                       
                    </div>
                    <div className="col-sm-12 col-md-4 col-md-push-2 rearrange-bottom">
                        <div id="g-block-17" className="block block-block footer-message  block-block-17">
                            <p className="maori-large">He taiao tōnui mō ngā reanga katoa</p>
                            <p>A flourishing environment for every generation</p>
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-2 col-md-pull-4">
                        <div id="block-menu-block-4" className="block block-menu-block footer-nav  block-menu-block-4">
                            <ul className="menu nav">
                                <li className="leaf menu-mlid-31702"><a
                                    href="https://environment.govt.nz/about-this-site/privacy/">Privacy</a>
                                </li>
                                <li className="leaf menu-mlid-39709"><a
                                    href="https://environment.govt.nz/about-us/official-information-act-requests/">OIA
                                    requests</a></li>
                                <li className="leaf menu-mlid-31701"><a
                                    href="https://environment.govt.nz/about-this-site/copyright/">Copyright</a>
                                </li>
                                <li className="leaf menu-mlid-5637"><a
                                    href="https://environment.govt.nz/about-this-site/disclaimer/">Disclaimer</a>
                                </li>
                                <li className="leaf menu-mlid-42149"><a
                                    href="https://environment.govt.nz/about-us/tenders/">Transparency
                                    statement</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-3 col-md-2">
                        <div id="block-block-15" className="block block-block footer-contact  block-block-15">
                            <h4>Contact us</h4>

                            <ul className="nav">
                                <li>0800 499 700</li>
                                <li><a href="mailto:info@mfe.govt.nz">info@mfe.govt.nz</a></li>
                                <li><a
                                    href="https://environment.govt.nz/">Subscribe
                                    to our newsletters</a></li>
                                <a
                                    href="https://twitter.com/mfe_news"><i
                                        className="fa fa-twitter"></i> </a>
                                <a
                                    href="https://www.facebook.com/ministryfortheenvironment/"><i
                                        className="fa fa-facebook"></i> </a>
                                <a
                                    href="https://www.instagram.com/nzenvironment"><i
                                        className="fa fa-instagram"></i> </a>
                                <a
                                    href="https://www.linkedin.com/company/ministryfortheenvironment"><i
                                        className="fa fa-linkedin"></i> </a>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div id="block-block-6" className="block block-block nzgovt  block-block-6">
                        <p><a href="https://www.govt.nz/"
                            useref="external">NZ Government Website</a></p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;