import React, { useState } from 'react';
import { ConsultationsService } from '../services/consultationsService';

const fetchConsultations = async () => {
    const consultationsService = new ConsultationsService();
    const newConsultations = [];
    const consultationCloseYears = [];
    const topics = [];
    await consultationsService.fetchAllConsultations().then(consultationsList => {
        consultationsList.forEach(consultation => {
            const consultationCloseYear = consultation.closedOn.substring(consultation.closedOn.length - 4)
            if(!consultationCloseYears.includes(consultationCloseYear.trim())){
                consultationCloseYears.push(consultationCloseYear.trim());
            }
            if(!topics.includes(consultation.topic)){
                topics.push(consultation.topic)
            }
            newConsultations.push(consultation)
        });
    }).catch(error => {
        throw error;
    });

    return { newConsultations, consultationCloseYears, topics };
}

function Landing() {
    const [consultations, setConsultations] = useState([]);
    const [allConsultations, setAllConsultations] = useState([]);
    const [consultationCloseYears, setConsultationCloseYears] = useState([]);
    const [topics, setTopics] = useState([]);
    const [filterYear, setFilterYear] = useState("All");
    const [filterTopic, setFilterTopic] = useState("All");



    React.useEffect(() => {
        fetchConsultations().then((returnValue)=>{
            setConsultations(returnValue.newConsultations)
            setAllConsultations(returnValue.newConsultations)
            setConsultationCloseYears(returnValue.consultationCloseYears);
            setTopics(returnValue.topics)
        }).catch(err => {
            console.error(`Error: ${err}`);
        });
    }, [setConsultations, setConsultationCloseYears]);

    React.useEffect(() => {
        document.title = 'Past MfE consultations (2014 -2020) | Ministry for the Environment'
    },[])

    const filterConsultations = (filterYear, filterTopic) => {
        const filteredConsultations = [];
            if(filterYear.trim()==="All" && filterTopic.trim()==="All"){
                setConsultations(allConsultations)
            }
            else{
                allConsultations.forEach((consultation)=>{
                    const year = consultation.closedOn.substring(consultation.closedOn.length - 4).trim();
                    const topic = consultation.topic.trim();
                    if(filterYear==="All" && filterTopic.trim()===topic){
                        filteredConsultations.push(consultation)
                    }
                    else if(filterYear===year && filterTopic.trim()==="All"){
                        filteredConsultations.push(consultation)
                    }
                    
                    else if(filterYear===year && filterTopic.trim()===topic){
                        filteredConsultations.push(consultation)
                    }
                })
                setConsultations(filteredConsultations);
            }
    }

    const resetFilter = () => {
        setConsultations(allConsultations);
        setFilterTopic("All")
        setFilterYear("All")
    }


    return (
        <div role="main" className="main-container container">
            <div className="row">
                <div className="col-sm-12">
                    <h2 className="element-invisible">You are here</h2>
                    <div className="breadcrumb"><span className="inline odd first"><a
                        href="https://environment.govt.nz/">Home</a></span>
                        <span className="delimiter">»</span> <span className="inline even"><a
                            href="https://environment.govt.nz/what-you-can-do/have-your-say/">Consultations</a></span>
                    </div>
                </div>
            </div>
            <div className="row">
                <section className="col-md-12">
                    <h1 className="page-header">Past consultations</h1> 
                    <div id="block-system-main" className="block block-system  block-system-main">
                        <div
                            className="view view-consultations view-id-consultations view-display-id-page_1 view-dom-id-a27194f430bed4b670a8f6dc52d0a22f">
                            <div className="view-filters">
                                <form
                                    action="http://localhost:3000/past-consultations"
                                    method="get" id="views-exposed-form-consultations-page-1" acceptCharset="UTF-8">
                                    <div>
                                        <div className="views-exposed-form">
                                            <div className="views-exposed-widgets clearfix">
                                                <div id="edit-field-opening-and-closing-dates-value-wrapper"
                                                    className="views-exposed-widget views-widget-filter-field_opening_and_closing_dates_value">
                                                    <label htmlFor="edit-field-opening-and-closing-dates-value">
                                                        Year </label>
                                                    <div className="views-widget">
                                                        <div id="edit-field-opening-and-closing-dates-value-value-wrapper">
                                                            <div id="edit-field-opening-and-closing-dates-value-value-inside-wrapper">
                                                                <div className="container-inline-date">
                                                                    <div
                                                                        className="form-item form-item-field-opening-and-closing-dates-value-value form-type-date-select form-group">
                                                                        <div id="edit-field-opening-and-closing-dates-value-value"
                                                                            className="date-padding clearfix">
                                                                            <div
                                                                                className="form-item form-item-field-opening-and-closing-dates-value-value-year form-type-select form-group">
                                                                                <div className="date-year"><select className="date-year form-control form-select"
                                                                                    id="edit-field-opening-and-closing-dates-value-value-year"
                                                                                    name="field_opening_and_closing_dates_value[value][year]" value={filterYear} onChange={(e)=>{
                                                                                        setFilterYear("All")
                                                                                        setFilterYear(e.target.value)}}>
                                                                                    <option value="All" defaultValue="selected">-Year</option>
                                                                                    {consultationCloseYears.sort().map(consultationCloseYear => (
                                                                                            <option value={consultationCloseYear}>{consultationCloseYear}</option>
                                                                                    ))}
                                                                                </select></div> <label className="control-label element-invisible"
                                                                                    htmlFor="edit-field-opening-and-closing-dates-value-value-year">Year</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="edit-field-section-topic-tid-wrapper"
                                                    className="views-exposed-widget views-widget-filter-field_section_topic_tid">
                                                    <label htmlFor="edit-field-section-topic-tid">
                                                        Section topic </label>
                                                    <div className="views-widget">
                                                        <div className="form-item form-item-field-section-topic-tid form-type-select form-group"><select
                                                            className="form-control form-select" id="edit-field-section-topic-tid"
                                                            name="field_section_topic_tid" value={filterTopic} onChange={(e)=>{
                                                                setFilterTopic(e.target.value)}}>
                                                            <option value="All" defaultValue="selected">- Any -</option>
                                                            {topics.sort().map(topic=>(
                                                                <option value={topic}>{topic}</option>
                                                            ))}
                                                        </select></div>
                                                    </div>
                                                </div>
                                                <div className="views-exposed-widget views-submit-button">
                                                    <button type="submit" id="edit-submit-consultations" name="filter" onClick={(e)=>{
                                                        e.preventDefault()
                                                        filterConsultations(filterYear.toString(), filterTopic.toString())}} value="Apply"
                                                        className="btn btn-info form-submit">Apply</button>
                                                </div>
                                                <div className="views-exposed-widget views-reset-button">
                                                    <button type="submit" id="edit-reset" name="op" value="Reset"
                                                        className="btn btn-default form-submit" onClick={(e)=>{
                                                            e.preventDefault()
                                                            resetFilter()
                                                            }}>Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="view-content">
                                <div className="table-responsive">
                                    <table className="views-table cols-3 table table-hover table-striped">
                                        <thead>
                                            <tr>
                                                <th className="views-field views-field-title">
                                                    Consultation </th>
                                                <th className="views-field views-field-field-section-topic">
                                                    Topic </th>
                                                <th className="views-field views-field-field-opening-and-closing-dates-1 active">
                                                    Closed on<span
                                                            className="icon-after icon glyphicon glyphicon-chevron-down" data-toggle="tooltip"
                                                            data-placement="bottom" title="" aria-hidden="true"
                                                            data-original-title="sort ascending"></span>
                                                    
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {consultations.map((consultation, index) => (
                                                <tr key={index} className={index === 0 ? 'odd views-row-first' : index % 2 === 0 ? 'even' : 'odd'}>
                                                    <td className="views-field views-field-title">
                                                        <a
                                                            href={`/past-consultation/` + consultation.id}>{consultation.title}</a>
                                                    </td>
                                                    <td className="views-field views-field-field-section-topic">
                                                        {consultation.topic}
                                                    </td>
                                                    <td className="views-field views-field-field-opening-and-closing-dates-1 active">
                                                        <span className="date-display-single" property="dc:date" datatype="xsd:dateTime"
                                                            content="2020-02-25T13:00:00+13:00">{consultation.closedOn}</span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Landing;