import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import './App.css';
import Header from './components/header';
import Footer from './components/footer';
import Landing from './pages/landing';
import Consultation from './pages/consultation';

function App() {
  return (
    <div className="App">
      <Header />
      <Router>
        <Route path='/past-consultations' component={Landing} exact />
        <Route path='/past-consultation/:id' component={Consultation} exact />
        <Route exact path='/' render={()=> <Redirect to='/past-consultations' />} />
      </Router>
      <Footer/>
    </div>
  );
}

export default App;
