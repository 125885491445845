import React from 'react';

function Header(props) {
    return (
        <header id="header" role="banner" className="container-fluid">
            <div className="container">
                <div className="logo-header">
                    <a className="name"
                        href="https://environment.govt.nz/"
                        title="Home">Ministry for the Environment</a>
                </div>
                <div id="block-menu-block-3" className="block block-menu-block aux-links  block-menu-block-3">
                    <ul className="menu nav">
                        <li className="leaf menu-mlid-27269"><a
                            href="https://environment.govt.nz/publications/"
                            title="">Publications</a></li>
                        <li className="leaf has-children menu-mlid-38399"><a
                            href="https://environment.govt.nz/what-you-can-do/have-your-say/">Consultations</a></li>
                    </ul>
                </div>
                <div className="navbar-header">
                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".mobile-navigation">
                        Menu
                </button>
                </div>
            </div>
            <div id="navbar" role="banner" className="navbar container-fluid navbar-default">
                <div className="navbar-main">
                    <div id="block-mfe-mobile-menu-mfe-mobile-menu" className="block block-mfe-mobile-menu mobile-navigation collapse  block-mfe-mobile-menu-mfe-mobile-menu">
                        <ul className="menu nav menu-about">
                        <li className="leaf menu-mlid-27269"><a
                            href="https://environment.govt.nz/publications/"
                            title="">Publications</a></li>
                        <li className="leaf has-children menu-mlid-38399"><a
                            href="https://environment.govt.nz/what-you-can-do/have-your-say/">Consultations</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;