import axios from 'axios';

export class ConsultationsService {

     fetchAllConsultations() {
        //Logic to axios get all posts from the API
        const apiURL = `${process.env.REACT_APP_BASE_URL}/getConsultations`|| "";
        return new Promise(async (resolve, reject) => {
            try {
                await axios.get(apiURL).then(returnValue => {
                    const posts = returnValue.data;
                    resolve(posts);
                }).catch(error => {
                    reject(error);
                })
            }
            catch (error) {
                reject(error);
            }
        })
    }

    fetchConsultation(consultation_id) {
        //logic to axios get a post content from the API
        const apiURL = `${process.env.REACT_APP_BASE_URL}/getConsultation?id=${consultation_id}`|| "";
        return new Promise(async (resolve, reject) => {
            try {
                await axios.get(apiURL).then(returnValue => {
                    const consultationContent = returnValue.data.body
                    const title = returnValue.data.title;
                    const consultation = {
                        title: title,
                        content: consultationContent
                    }
                    resolve (consultation)
                })
            }
            catch (error) {
                reject(error);
            }
        })
    }
}